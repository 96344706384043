import { Text } from 'components';
import AppleStoreButton from 'pages/start/success/components/AppleStoreButton';
import GooglePlayButton from 'pages/start/success/components/GooglePlayButton';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import Lottie from 'lottie-react';
import success from 'assets/lotties/success.json';
import { AppState } from 'state/types';
import { useSelector } from 'react-redux';
import { StoresLinks, StoresLinksWY } from 'utils/constants/externalLinks';
import AppleStoreLogo from 'assets/icons/appStore_badge.svg';
import GooglePlayLogo from 'assets/icons/googlePlay_badge.svg';

interface RegisterCompletedProps {
  successTitle?: string;
  successInfo?: string;
  variant?: string;
}

const RegisterCompleted: FC<RegisterCompletedProps> = ({
  successTitle,
  successInfo,
  variant,
}) => {
  const { user } = useSelector((state: AppState) => state.user);
  return (
    <Container>
      <Inner>
        <SvgContainer>
          <Lottie animationData={success} loop={false} />
        </SvgContainer>
        <Title>
          {successTitle
            ? successTitle
            : 'You have successfully created your account!'}
        </Title>
        <Label
          dangerouslySetInnerHTML={{
            __html: successInfo
              ? successInfo.replace('${email}', user?.email)
              : 'Download the Raising Dog app on your phone using the buttons below! Use your email <span>${email}</span> and created password to log in.'.replace(
                  '${email}',
                  user?.email,
                ),
          }}
        />
        {variant === 'RD' && (
          <ButtonContainer>
            <AppleStore
              onClick={() => window.open(StoresLinks.AppleStore, '_system')}
            />
            <GooglePlay
              onClick={() => window.open(StoresLinks.GooglePlay, '_system')}
            />
          </ButtonContainer>
        )}
        {variant === 'WY' && (
          <ButtonContainer>
            <AppleStore
              onClick={() => window.open(StoresLinksWY.AppleStore, '_system')}
            />
            <GooglePlay
              onClick={() => window.open(StoresLinksWY.GooglePlay, '_system')}
            />
          </ButtonContainer>
        )}
        {variant === 'combo' && (
          <ButtonsMain>
            <XsellButtonsLabel>
              Click the buttons below to download the apps
            </XsellButtonsLabel>
            <Buttons>
              <TitleButtons>Raising Dog</TitleButtons>
              <ButtonsInner>
                <AppleStore
                  onClick={() => window.open(StoresLinks.AppleStore, '_system')}
                />
                <GooglePlay
                  onClick={() => window.open(StoresLinks.GooglePlay, '_system')}
                />
              </ButtonsInner>
            </Buttons>

            <Buttons>
              <TitleButtons>Walking Yoga</TitleButtons>
              <ButtonsInner>
                <AppleStore
                  onClick={() =>
                    window.open(StoresLinksWY.AppleStore, '_system')
                  }
                />
                <GooglePlay
                  onClick={() =>
                    window.open(StoresLinksWY.GooglePlay, '_system')
                  }
                />
              </ButtonsInner>
            </Buttons>
          </ButtonsMain>
        )}
      </Inner>
    </Container>
  );
};

export default RegisterCompleted;

const Inner = styled.section`
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
`;

const Container = styled.div`
  border-top: 1px solid #f0f0f0;
  width: 100%;
  @media ${tablet} {
  }
`;

const Title = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: -0.028rem;
  margin-bottom: 0.75rem;

  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Label = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 2.375rem;
  max-width: 28.75rem;
  width: 100%;

  span {
    font-weight: 700;
  }

  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1rem;
`;

const SvgContainer = styled.div`
  width: 8.75rem;
  height: 8.75rem;
  margin-bottom: 0.75rem;
  svg {
    width: 8.75rem;
    height: 8.75rem;
  }
`;

const AppleStore = styled(AppleStoreLogo)`
  max-width: 10.21875rem;
  width: 100%;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;
const GooglePlay = styled(GooglePlayLogo)`
  max-width: 10.21875rem;
  width: 100%;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const ButtonsMain = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
`;

const XsellButtonsLabel = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
`;

const Buttons = styled.section`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid #c7c9d9;
  background: #f0f0f0;
  width: 100%;
`;

const TitleButtons = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.024rem;
`;

const ButtonsInner = styled.section`
  display: flex;
  gap: 1rem;
`;
