export enum SocialLinks {
  Instagram = 'https://www.instagram.com/raising.dog/',
  Facebook = 'https://www.facebook.com/profile.php?id=61552134328976',
  Twitter = '#',
  Reddit = '#',
}
export enum StoresLinks {
  AppleStore = 'https://apps.apple.com/us/app/raising-dog-training-tricks/id6466413368',
  GooglePlay = 'https://play.google.com/store/apps/details?id=com.raisingdog.app',
}

export enum StoresLinksWY {
  AppleStore = 'https://apps.apple.com/us/app/walking-yoga/id6636476252',
  GooglePlay = 'https://play.google.com/store/apps/details?id=com.walkingyoga.app',
}
